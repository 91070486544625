<template>
  <div class="pa-5" :class="$vuetify.breakpoint.xs ? '' : 'px-10'">
    <div class="mb-8" :class="$vuetify.breakpoint.xs ? 'd-block' : 'd-flex'">
      <!-- <div class="mobile">
              <img
                class=""
                :src="logo"
                alt="logo"
                style="width:5rem;cursor:pointer"
                @click="redirectToHome()"
                id="logo-img"
              />
            </div> -->

      <v-spacer></v-spacer>
      <h1>Privacy Policy</h1>
      <v-spacer></v-spacer>
    </div>
    <v-row>
      <v-col cols="12">
        <div class="mb-5">
          <h3 class="mb-2">Privacy Policy of Gricd</h3>
          <p>
            GRICD operates the www.farm.gricd.com website, an agricultural
            online platform that offers the opportunity to partner with and
            empowers smallholder farmers enabling them scale activities. This
            page informs website visitors regarding our policies with the
            collection, use, and disclosure of Personal Information when using
            the GRICD website. If you choose to use our Service, then you agree
            to the collection and use of information in relation to this policy.
            The Personal Information collected is used for providing and
            improving the Service. We will not use or share your information
            with anyone except as described in this Privacy Policy. The terms
            used in this Privacy Policy have the same meanings as in our Terms
            and Conditions, which is accessible at www.farm.gricd.com unless
            otherwise defined in this Privacy Policy.
          </p>
        </div>

        <div class="mb-5">
          <h3 class="mb-2">Collection and Use of Information</h3>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personal information, including but not
            limited to your name, phone number, and postal address. The
            information that we collect will be used to contact or identify you.
          </p>
        </div>

        <div class="mb-5">
          <h3 class="mb-2">Log Data</h3>
          <p>
            Whenever a user visits our Service, we collect information sent to
            us by the user’s browser. It is called Log Data. This Log Data may
            include information such as your computer’s Internet Protocol ("IP")
            address, browser version, pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, and
            other statistics.
          </p>
        </div>

        <div class="mb-5">
          <h3 class="mb-2">Cookies</h3>
          <p>
            Cookies are files with small amounts of data that are sent to your
            browser from the website that you visit and are stored on your
            computer’s hard drive. GRICD website uses these "cookies" to
            collection information with the aim of improving our Services. You
            have the option to either accept or refuse these cookies and know
            when a cookie is being sent to your computer. If you choose to
            refuse our cookies, you may not be able to use some portions of our
            Service.
          </p>
        </div>
        <div class="mb-5">
          <h3 class="mb-2">Third-Party involvements</h3>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ol type="1">
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analysing how our Service is used.</li>
          </ol>
          <p>
            These third parties will have access to your Personal Information.
            The reason is to perform the tasks assigned to them on our behalf.
            However, they are obligated not to disclose or use the information
            for any other purpose.
          </p>
        </div>
        <div class="mb-5">
          <h3 class="mb-2">Security</h3>
          <p>
            We value your trust in providing us with your Personal Information,
            thus we are striving to use commercially acceptable means of
            protecting it. But note that no method of transmission over the
            internet, or method of electronic storage is 100% secure and
            reliable, and we cannot guarantee its absolute security.
          </p>
        </div>
        <div class="mb-5">
          <h3 class="mb-2">Links to Other Sites</h3>
          <p>
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
        </div>
        <div class="mb-5">
          <h3 class="mb-2">Changes to This Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. Users will be
            notified of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page. This privacy policy was created with the GDPR
            Generator.
          </p>
        </div>
        <div class="mb-5">
          <h3 class="mb-2">Contact Us</h3>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us via email (info@gricd.com)
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logo from '../../assets/Logo/logo.png';
export default {
  name: 'Privacy',
  data() {
    return {
      logo,
    };
  },
  methods: {
    redirectToHome() {
      this.$router.push({path: '/'});
    },
  },
};
</script>

<style></style>
