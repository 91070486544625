<template>
        <div class="pa-5" :class="$vuetify.breakpoint.xs ? '' : 'px-10'">
          <div
            class="mb-8"
            :class="$vuetify.breakpoint.xs ? 'd-block' : 'd-flex'"
          >
            <!-- <div class="mobile">
              <img
                class=""
                :src="logo"
                alt="logo"
                style="width:5rem;cursor:pointer"
                @click="redirectToHome()"
                id="logo-img"
              />
            </div> -->

            <v-spacer></v-spacer>
            <h1>Terms & Conditions</h1>
            <v-spacer></v-spacer>
          </div>
          <v-row>
            <v-col cols="12">
              <div class="mb-7">
                <p class="font-italic">
                  Please read these Terms and Conditions, which set forth the
                  legally binding terms and conditions for your use of the
                  services (the “Service”) offered by Gricd Integrated Agro
                  Allied Services Limited. (“Gricd” or the “Company”) and the
                  website at www.farm.gricd.com
                </p>
                <p>
                  Your access to use of the Service is conditioned on your
                  acceptance of and compliance with these Terms & Conditions.
                  These Terms & Conditions apply to all visitors, users and
                  others who access or use the Service and all applicable laws
                  and all conditions or policies referenced here (collectively,
                  the “Terms & Conditions”)
                </p>
                <p>
                  By accessing or using the Service you agree to be bound by
                  these Terms & Conditions. If you disagree with any part of the
                  terms then you may not access the Service and that the Company
                  may use any information the Company obtains about you in
                  accordance with the provisions of the Privacy Policy.
                </p>
                <p class="font-weight-black">
                  This document was last updated on December 29, 2017.
                </p>
              </div>

              <div class="mb-8">
                <h3 class="mb-2">Summary of Service</h3>
                <p>
                  Gricd is a budding agri-business agency that is geared towards
                  providing solutions to a vast number of problems in
                  agriculture. We aim at solving these problems through
                  processing crops for extended shelf-life, proffering workable
                  solutions to farmers, cultivating and aggregating crops to
                  curb the food shortages in numerous localities and sourcing
                  funds for farmers. Participants who indicate interest on the
                  platform choose to fund a farm(s). Each farm has an amount
                  needed for funding, the possible yields or returns from
                  harvest, and the time duration till harvest. The participant
                  subscribers who get updates on the farming process, with the
                  possibility of getting paid upon a successful harvest;
                  however, if unsuccessful, the subscribers receive their
                  capital back subject to the insurance coverage. For a better
                  experience, while using our Service, we may require you to
                  provide us with certain personal information, including but
                  not limited to your name, phone number, and postal address.
                  The information that we collect will be used to contact or
                  identify you.
                </p>
              </div>

              <div class="mb-8">
                <h3 class="mb-2">Definitions</h3>
                <p>
                  In these Terms & Conditions, we refer to users contributing
                  funds as “Subscribers.” Other users of the Service and
                  visitors to the Site are referred to collectively as “Users.”
                  Information about happenings and progress reports on the farms
                  are referred to as “Updates”. A “Farming Duration” refers to a
                  complete farming season from planting to harvest and sales. A
                  “Farming duration” in livestock refers to cycle for growth,
                  processing and sale of livestock.
                </p>
              </div>

              <div class="mb-8">
                <h3 class="mb-2">Acceptance of Terms</h3>
                <p>
                  The Service is offered subject to acceptance of all the terms
                  and conditions contained in these Terms & Conditions and all
                  other operating rules, policies, and procedures that may be
                  published on the Site by the Company, which are incorporated
                  by reference, including operating rules, policies, and
                  procedures of third party service providers to the Site that
                  are referenced herein. These Terms & Conditions apply to every
                  user of the Service. In addition, some Services offered
                  through the Site may be subject to additional terms and
                  conditions adopted by the Company. Your use of those Services
                  is subject to those additional terms and conditions, which are
                  incorporated into these Terms & Conditions by this reference.
                </p>
                <p>
                  The Company reserves the right, at its sole discretion, to
                  modify or replace these Terms & Conditions from time to time
                  by posting the updated terms on the Site. It is your
                  responsibility to check the Terms & Conditions periodically
                  for changes. If you object to any such changes, your sole
                  recourse will be to cease using the Site and the Service. Your
                  continued use of the Service following the posting of any
                  changes to the Terms of Use will indicate your acknowledgement
                  of such changes and agreement to be bound by the terms and
                  conditions of such changes.
                </p>
                <p>
                  The Company reserves the right to change, suspend, or
                  discontinue the Service (including, but not limited to, the
                  availability of any feature, database, or Content) at any time
                  for any reason. The Company may also impose limits on certain
                  features and Services or restrict your access to parts of or
                  the entire Site without notice or liability.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Accounts</h3>
                <p>
                  When you create an account with us, you must provide us with
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the Terms &
                  Conditions, which may result in immediate termination of your
                  account on our Service.
                </p>
                <p>
                  You are responsible for safeguarding the password that you use
                  to access the Service and for any activities or actions under
                  your password, whether your password is with our Service or a
                  third-party service.
                </p>
                <p>
                  You agree not to disclose your password to any third party.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorized use of your account.
                </p>
                <p>
                  You may not use as a username the name of another person or
                  entity or that is not lawfully available for use, a name or
                  trade mark that is subject to any rights of another person or
                  entity other than you without appropriate authorization, or a
                  name that is otherwise offensive, vulgar or obscene.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Subscription Terms</h3>
                <p>
                  1. The Subscriber will receive periodic Updates on the status
                  of the farm by email. The Updates may also available in your
                  account on the Website. You are expected to review all
                  notifications, updates and reports from Gricd as they
                  represent a record of information pertaining to your
                  contribution during a Farming Duration
                </p>
                <p>
                  2. You are entitled to request for a physical visit to the
                  farm you are subscribed to, however, you must provide us with
                  at least 7days notice to schedule a visit. Kindly note that
                  there would be a cost incurred for such trips.
                </p>
                <p>
                  3. At the beginning of a Farming Duration, costs related to
                  the lease of land for farming, seedling/livestock,
                  fertilizers, maintenance costs, and the cost of supervision
                  and administration will be deducted from your subscription
                  contribution. All costs are included in your subscription
                  contribution.
                </p>
                <p>
                  4. You agree that by subscribing to a farm, your contribution
                  is tied and unavailable for the entire duration of the Farming
                  Duration and neither GRICD nor the farmers can or will refund
                  your contribution until the successful completion of a Farming
                  Duration
                </p>
                <p>
                  5. Any profits from a successful Farming Duration of a
                  Subscriber’s farm are remitted to the electronic wallet on the
                  Platform when the produce is harvested and sold (i.e. upon
                  completion of the Farming Duration)
                </p>
                <p>
                  6. At the end of a successful Farming Duration, you can decide
                  whether to withdraw your funds from the electronic wallet on
                  the Platform and transfer to a bank account you provide us
                  with or to subscribe to another farm for a new Duration
                  subject to that Farm’s terms & conditions
                </p>
                <p>
                  7. At the end of a Farming Duration, the Subscriber’s Expected
                  Returns is disbursed from any profits made during harvest.
                </p>
                <p>
                  8. You acknowledge that you do not own any of the farms
                  cultivated and are only entitled to your capital and/or a part
                  of the proceeds of any Farming Duration you participate in.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Links to Other Sites</h3>
                <p>
                  Our Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us. Therefore,
                  we strongly advise you to review the Privacy Policy of these
                  websites. We have no control over and assume no responsibility
                  for the content, privacy policies, or practices of any
                  third-party sites or services.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Subscribers’ Representations & Warranties</h3>
                <p>
                  Representations and warranties are statements and promises
                  made by you to the Company, which we rely on as being accurate
                  in our dealings with you. You make the following
                  representations and warranties to us at the time of agreeing
                  to these Terms and every time you use the Platform or
                  Services, including the time you are matched to a Loan
                  Agreement;
                </p>
                <p>1. You are over the age of 21 years;</p>
                <p>
                  2. You are of sound mind and have the capacity to enter into
                  these Terms & Conditions and be matched to a farm;
                </p>
                <p>
                  3. All personal information that you provide about yourself is
                  accurate and true to the best of your knowledge;
                </p>
                <p>
                  4. You hold a bank account in Nigeria in your own name and
                  will use this account to transfer money to our account when
                  you subscribe to a farm and receive refunds of contributions
                  made and profits earned;
                </p>
                <p>
                  5. You have carefully considered the risks involved with using
                  the Website, the Services and being matched to a farm and also
                  the risks associated with any form of cash contribution or
                  donation, including but not limited to the possibility of
                  losing all the money you contribute;
                </p>
                <p>
                  6. You will only ever subscribe with your own money unless you
                  are authorised on behalf of a third party (“Third Party”) and
                  we agree that you may act on behalf of that Third Party.
                </p>
                <p>
                  7. If you are registering or accepting these Terms on behalf
                  of a legal entity such as a company, trust or partnership, you
                  are legally authorised to do so and we may request evidence of
                  such legal entitlement (by way of a copy of any document which
                  shows the valid and subsisting authorisation); and
                </p>
                <p>
                  8. You are not breaching any laws or regulations that are
                  applicable to you or any company, trust or partnership upon
                  whose instructions you are acting.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Intellectual Property</h3>
                <p>
                  The Service and its original content, features and
                  functionality are and will remain the exclusive property of
                  Gricd Integrated Agro Allied Services Limited. The Service is
                  protected by copyright, trademark, and other laws of The
                  Federal Republic of Nigeria. Our trademarks and trade dress
                  may not be used in connection with any product or service
                  without the prior written consent of Gricd Integrated Agro
                  Allied Services Limited.
                </p>
                <p>
                  User shall not directly or indirectly: (i) decipher,
                  decompile, disassemble, reverse engineer, or otherwise attempt
                  to derive any source code or underlying ideas or algorithms of
                  any part of the Service, except to the extent applicable laws
                  specifically prohibit such restriction; (ii) modify,
                  translate, or otherwise create derivative works of any part of
                  the Service; or (iii) copy, rent, lease, distribute, or
                  otherwise transfer any of the rights that you receive
                  hereunder.
                </p>
                <p>
                  If you choose to provide technical, business or other feedback
                  to the Company concerning the Site or the Services
                  (collectively, “Feedback”), the Company will be free to use,
                  disclose, reproduce, license, or otherwise distribute or
                  exploit such Feedback in its sole discretion without any
                  obligations or restrictions of any kind, including
                  intellectual property rights or licensing obligations. You
                  understand and agree that the incorporation by the Company of
                  Feedback into any of its products or services does not grant
                  you any proprietary rights therein.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Links to other Websites</h3>
                <p>
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by Gricd
                </p>
                <p>
                  Gricd Integrated Agro Allied Services Limited has no control
                  over and assumes no responsibility for, the content, privacy
                  policies, or practices of any third-party web sites or
                  services. You further acknowledge and agree that Gricd shall
                  not be responsible or liable, directly or indirectly, for any
                  damage or loss caused or alleged to be caused by or in
                  connection with use of or reliance on any such content, goods
                  or services available on or through any such web sites or
                  services.
                </p>
                <p>
                  We strongly advise you to read the terms and conditions and
                  privacy policies of any third-party web sites or services that
                  you visit.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Termination</h3>
                <p>
                  We may terminate or suspend your account immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if you breach the Terms &
                  Conditions.
                </p>
                <p>
                  Upon termination, your right to use the Service will
                  immediately cease. If you wish to terminate your account, you
                  may simply discontinue using the Service.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Insurance</h3>
                <p>
                  Farm plots and livestock have been insured. Where Gricd makes
                  a claim under the insurance policy, and the insurance claim is
                  settled by the insurance company, Gricd shall pass on the
                  benefits of such claims to Subscribers in the proportion of
                  their Subscription Contribution in any affected Farm
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Indemnity</h3>
                <p>
                  Subject to paragraph 10 (Insurance) and any insurance claims
                  settled, you agree that the Company has no obligation to
                  reimburse or indemnify you for any cost, loss or liability
                  which you incur as a result of the failure of a farm or
                  livestock to yield according to expectations.
                </p>
                <p>
                  You agree to indemnify and hold harmless the Company, any
                  farmer, and all our other agents or sub-contractors, against
                  all liabilities, costs, expenses, damages and losses
                  (including any direct, indirect or consequential losses, loss
                  of profit, loss of reputation and all interest penalties and
                  legal and other reasonable professional costs and expenses)
                  suffered or incurred as a result of:
                </p>
                <p>
                  1. Your fraudulent or illegal use of the Platform, the
                  Services or the Website;
                </p>
                <p>
                  2. Your negligence or any default by you of any of these Terms
                  or the terms of any Loan Agreement to which you are matched;
                </p>
                <p>
                  3. Any inaccurate or incomplete information that you have
                  knowingly provided to us;
                </p>
                <p>
                  4. Your allowing any other person to access your account
                  either with your permission or as a result of your failure to
                  keep your username and password private;
                </p>
                <p>
                  5. Any service that you have offered, whether with or without
                  our permission, to another third party using our Platform,
                  Services or Website;
                </p>
                <p>
                  6. Any claim made against you for actual or alleged
                  infringement of the Company’s Intellectual Property Rights or
                  any actual or alleged infringement of a third party’s
                  Intellectual Property Rights arising out of or in connection
                  with the Services or your use of the Platform
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Limitation of Liability</h3>
                <p>
                  In no event shall Gricd, its directors, employees, partners,
                  agents, suppliers, or affiliates, be liable for any indirect,
                  incidental, special, consequential or punitive damages,
                  including without limitation, loss of profits, data, use,
                  goodwill, or other intangible losses, resulting from (i) your
                  access to or use of or inability to access or use the Service;
                  (ii) any conduct or content of any third party on the Service;
                  (iii) any content obtained from the Service; and (iv)
                  unauthorized access, use or alteration of your transmissions
                  or content, whether based on warranty, contract, tort
                  (including negligence) or any other legal theory, whether or
                  not we have been informed of the possibility of such damage,
                  and even if a remedy set forth herein is found to have failed
                  of its essential purpose.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Disclaimer</h3>
                <p>
                  Your use of the Service is at your sole risk. The Service is
                  provided on an “AS IS” and “AS AVAILABLE” basis. The Service
                  is provided without warranties of any kind, whether express or
                  implied, including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose,
                  non-infringement or course of performance.
                </p>
                <p>
                  Gricd, its subsidiaries, affiliates, and its licensors do not
                  warrant that a) the Service will function uninterrupted,
                  secure or available at any particular time or location; b) any
                  errors or defects will be corrected; c) the Service is free of
                  viruses or other harmful components; or d) the results of
                  using the Service will meet your requirements.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Governing Law</h3>
                <p>
                  These Terms shall be governed and construed in accordance with
                  the laws of The Federal Republic of Nigeria, without regard to
                  its conflict of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms
                  will not be considered a waiver of those rights. If any
                  provision of these Terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  Terms will remain in effect. These Terms & Conditions
                  constitute the entire agreement between us regarding our
                  Service, and supersede and replace any prior agreements we
                  might have between us regarding the Service.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Changes to Terms & Conditions</h3>
                <p>
                  These Terms shall be governed and construed in accordance with
                  the laws of The Federal Republic of Nigeria, without regard to
                  its conflict of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms
                  will not be considered a waiver of those rights. If any
                  provision of these Terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  Terms will remain in effect. These Terms & Conditions
                  constitute the entire agreement between us regarding our
                  Service, and supersede and replace any prior agreements we
                  might have between us regarding the Service.
                </p>
              </div>
              <div class="mb-8">
                <h3 class="mb-2">Contact Us</h3>
                <p>
                  If you have any questions about these Terms & Conditions,
                  please contact us at: Farm@gricd.com.ng or 16 Ladipo Kuku,
                  Ikeja, Lagos, Nigeria.
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
</template>

<script>
import logo from '../../assets/Logo/logo.png';
export default {
  name: 'Terms',
  data() {
    return {
      logo,
    };
  },
  methods: {
    redirectToHome() {
      this.$router.push({path: '/'});
    },
  },
};
</script>

<style></style>
